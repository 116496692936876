import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerSdaCategoryPage from "../../components/molecules/InnerSdaCategoryPage"
import { highPhysicalSupportPage } from "../../content/SdaCategories"

const HighPhysicalSupport = () => (
  <Layout>
    <Seo title="High Physical Support" />
    <InnerSdaCategoryPage data={highPhysicalSupportPage} />
  </Layout>
)

export default HighPhysicalSupport
